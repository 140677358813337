import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

import BannerDesktopLG from '../../assets/transacao-segura-banner-desktop-lg.png'
import BannerDesktopXL from '../../assets/transacao-segura-banner-desktop-xl.png'
import BannerMobile from '../../assets/transacao-segura-banner-mobile.png'
import BannerTablet from '../../assets/transacao-segura-banner-tablet.png'

export const Wrapper = styled.section`
  background: ${orange.extra};
`

export const Section = styled.div`
  min-height: 632px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: ${orange.extra} url(${BannerMobile});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center bottom;

  @media ${device.tablet} {
    min-height: 464px;

    background: ${orange.extra} url(${BannerTablet});
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: left 50px center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media ${device.desktopLG} {
    min-height: 577px;
    background: ${orange.extra} url(${BannerDesktopLG});
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: left center;
  }

  @media ${device.desktopXL} {
    min-height: 801px;

  background: ${orange.extra} url(${BannerDesktopXL});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: left center;
  }
`

export const Row = styled.div`
  @media ${device.desktopLG} {
      width: 100%;
    }
`

export const Col = styled.div`
 @media ${device.desktopXXXL} {
   padding-left: 130px;
  }
`

export const Title = styled.h2`
  font-family: "Sora", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${white};
  margin-bottom: 16px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${white};

  @media ${device.tablet} {
    margin-right: -10px;
    font-size: 16px;
    line-height: 19px;
  }
`
